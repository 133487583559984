import createAuth0Client from "@auth0/auth0-spa-js";
import { computed, reactive, watchEffect } from "vue";
// import router from '@/router'
import authConfig from "@/auth_config.json";
import axios from "axios";
import _each from "lodash/each";
import _includes from "lodash/includes";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _has from "lodash/has";
import _head from "lodash/head";
import { NAV } from "@/components/Touchpoint/nav";
import { API, MOBILE, API_EXTERNAL } from "@/api";
import { _ } from "core-js";

import { globalVariable } from '@/globalVariable';


let api_endpoint = "https://api-prod.touchpointglobal.com";

if (window.location.hostname == "localhost") {
  api_endpoint = "http://127.0.0.1:3000";
  // api_endpoint = "https://api-uat.touchpointglobal.com";
  // api_endpoint = "https://api-prod.touchpointglobal.com";
  // api_endpoint = "https://api-dev.touchpointglobal.com";
}

if (window.location.hostname == "uat.tplive.io") {
  // api_endpoint = "https://api-uat.tplive.io";
  // api_endpoint = "https://api-uat.touchpointglobal.com";
  api_endpoint = "https://api-dev.touchpointglobal.com";
}

if (window.location.hostname == "production.tplive.io" || window.location.hostname == "beta.tplive.io") {
  api_endpoint = "https://api-dev.touchpointglobal.com";
}

let client;
const state = reactive({
  loading: true,
  isAuthenticated: false,
  user: {},
  popupOpen: false,
  error: null,
  client_code: null,
  apikey: null,
  token: null,
  client: null,
});

async function loginWithPopup() {
  state.popupOpen = true;

  try {
    await client.loginWithPopup(0);
  } catch (e) {
    console.error(e);
  } finally {
    state.popupOpen = false;
  }

  state.user = await client.getUser();
  state.isAuthenticated = true;
}

async function handleRedirectCallback() {
  state.loading = true;

  try {
    await client.handleRedirectCallback();
    state.user = await client.getUser();
    state.isAuthenticated = true;
  } catch (e) {
    state.error = e;
  } finally {
    state.loading = false;
  }
}

function loginWithRedirect(o) {
  return client.loginWithRedirect(o);
}

function getIdTokenClaims(o) {
  return client.getIdTokenClaims(o);
}

function getTokenSilently() {
  if (!client) return;
  return client.getTokenSilently();
}

function getTokenWithPopup(o) {
  return client.getTokenWithPopup(o);
}



async function logout(o) {
  if (client) {
    await client.logout(o);
    this.reset();
  }
  return localStorage.clear();
}
function reset() {
  state.client = null;
  client = null;
  return;
}

function setClient(o) {
  if (window.location.hostname == "uat.tplive.io") {
    state.apikey = o.uat;
  } else {
    state.apikey = o.prod;
  }

  // if (window.location.hostname == "localhost") {
  //   state.apikey = o.uat;
  // }

  o.redirect_uri = `${location.origin}/callback?client=${state.client_code}`;
  return (state.client = o);
}

const authPlugin = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  client: computed(() => state.client),
  getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  handleRedirectCallback,
  loginWithRedirect,
  loginWithPopup,
  logout,
  doAuth,
  setClient,
  getClient,
  userInRole,
  reset,
  isTpAdmin,
  isAdmin
};

function callbackRedirect() {
  // router.push(
  //   appState && appState.targetUrl
  //     ? appState.targetUrl
  //     : '/'
  // );
  router.push(appState && appState.targetUrl ? appState.targetUrl : "/");
}

function userInRole(role) {
  return _has(state.user.db.roles, role, false);
}

function getClient() {
  return client;
}

function isTpAdmin() {
  return state.user.isTpAdmin;
}

function isAdmin() {
  return state.user.isAdmin;
}

export const getState = state;

export const resetClient = async (to, from, next) => {
  const { reset } = authPlugin;
  reset();
  return next();
};

export const routeGuardTp = async (to, from, next) => {
  const { user } = authPlugin;

  console.log('user', user)

  // if (isTpAdmin())
  //   return next({ name: "Unauthorised", params: { client: state.client.key } });

  return next();
}

export const routeGuard = async (to, from, next) => {
  const { isAuthenticated, loading, doAuth, setClient } = authPlugin;

  let to_stringy = null;
  try {
    to_stringy = JSON.stringify(to);
  } catch (e) { }
  localStorage.setItem("redirect", to_stringy);

  if (!state.client) {
    state.client_code = to.params.client;

    let client_config = _get(authConfig.client, state.client_code, false);

    if (window.location.hostname == "uat.tplive.io"
      // || window.location.hostname == "localhost"
    ) {
      if (_has(authConfig.client, state.client_code + "_uat")) {
        client_config = _get(authConfig.client, state.client_code + "_uat", false);
      }
    }

    setClient({ ...authConfig.default, ...client_config });

    await doAuth(state.client, callbackRedirect);
  }


  const verify = async () => {


    // If the user is authenticated, continue with the route
    if (isAuthenticated.value) {

      if (to.params.module) {
        document.title =
          `${state.client.key.toUpperCase()}: ${to.params.module.toUpperCase()}` || "Touchpoint";
      } else {
        document.title = `${state.client.key.toUpperCase()}` || "Touchpoint";
      }


      if (!state.user.db) {

        if (to.name == 'backup') {
          state.user.db = { email: "offline" };
          state.user.isTpAdmin = false;
          state.user.isAdmin = false;
          state.user.perms = {};
          return next();
        }

        try {

          let cb = Date.now();

          let params = `cb=${cb}`;

          if (_has(to.query, 'otp')) {
            params += `&support_otp=${to.query.otp}`;
          }

          // let auth0_id = encodeURIComponent(state.user.sub);

          let resp = await axios.get(`${api_endpoint}/api/users/account?${params}`, {
            params: {},
            headers: {
              Authorization: "Bearer " + state.accessToken,
              "x-api-key": state.apikey,
              "x-waf-key": state.user['https://tplive.io/wk']
            },
          });






          if (resp) {
            let user = resp.data;

            if (user) {
              state.user.db = user;
              // state.accessToken = user.jwt;
              state.user.status = user.status;

              state.user.isTpAdmin = false;
              state.user.isAdmin = false;



              let roles = _get(user, 'use_roles', false)


              if (roles) {
                state.user.perms = user.perms;
                state.user.isTpAdmin = user.istpadmin;
                state.user.isAdmin = user.isadmin;
              } else {

                if (user.type == "admin") {
                  state.user.isTpAdmin = true;
                  state.user.isAdmin = true;
                }

                if (user.type == "siteadmin") state.user.isAdmin = true;
              }

            } else {
              return next({ name: "Unauthorised", params: { client: state.client.key } });
            }
          }
        } catch (e) {
          console.log(e);
          // trap for 500 error
          // if (e.code == 'ERR_NETWORK') {
          //   state.user.db = { email: "offline" };
          //   state.user.isTpAdmin = false;
          //   state.user.isAdmin = false;
          //   state.user.perms = {};

          //   return next({ name: "mobilehome", params: { client: state.client.key, id: '5edad7b32ff34d1a82f36a8693005f59' } });
          // }
          return next({ name: "Unauthorised", params: { client: state.client.key } });
        }
      }

      globalVariable.updateValue(state.accessToken);


      API.init(state.user.db.csrf_token, state.apikey, state.accessToken, state.user['https://tplive.io/wk']);
      MOBILE.init(state.user.db.csrf_token, state.apikey, state.accessToken, state.user['https://tplive.io/wk']);
      API_EXTERNAL.init(state.apikey, state.accessToken, state.user['https://tplive.io/wk']);

      let roles = _get(state.user.db, 'use_roles', false)

      if (!state.user.perms) {
        let resp = await axios.post(
          api_endpoint + "/api/page/712E6560754B425BB95F936BFB551FC9",
          { auth0_id: state.user.sub },
          {
            headers: {
              Authorization: "Bearer " + state.accessToken,
              "x-api-key": state.apikey,
              "x-csrf-token": state.user.db.csrf_token
            },
          }
        );

        if (resp) {
          state.user.perms = {};
          _each(resp.data.data, (i) => {
            state.user.perms[i.tab_slug] = i.access_level;
          });
        }
      }


      if (roles) {

        if (!_has(state.user.db, "roles") || _isEmpty(state.user.db.roles))
          return next({ name: "Unauthorised", params: { client: state.client.key } });

        // role id r_32GXjCEqVf84We2rNYml9HEe4HGoF8 is the "Portals / 0000 / Enforce Portal Only View" role
        if (state.user.db.roles['r_32GXjCEqVf84We2rNYml9HEe4HGoF8']) {

          state.user.is_supplier = true;

          // pass the mobile and user profile routes through
          if (to.href.includes("mobile")) return next();
          if (to.href.includes("profile")) return next();

          // get the first supplier in the array
          let supplier = _head(state.user.db.suppliers)

          // must have a supplier
          if (!supplier) {
            return next({ name: "Unauthorised", params: { client: state.client.key } });
          }


          let module = supplier.name.toLowerCase().replace(" ", "-");

          // this is to trap for the main suppliers slug and just accept the route.  eg the user is navigating within the supplier module tabsets
          if (to.params.module == module && to.params.slug == 's_C6C398748A22467E848C9603F7CB1C4F') return next();

          return next({
            name: "supplier.go",
            params: { client: state.client.key, module: module, id: supplier.id, slug: 's_C6C398748A22467E848C9603F7CB1C4F' },
          });
        } else {
          if (to.params.module) {
            if (state.user.isTpAdmin === true) return next();

            if (state.user.isAdmin === true) return next();

            let nav_module = null;
            _each(NAV.data, (i) => {
              if (_has(i, "slug")) {
                if (i.slug == to.params.slug) {
                  nav_module = i;
                  return;
                }
              }
              if (_has(i, "children")) {
                _each(i.children, (c) => {
                  if (c.slug == to.params.slug) {
                    nav_module = c;
                    return;
                  }
                });
              }
            });

            state.user.is_supplier = false;

            return next();
          }

          return next();
        }
      } else {

        let user_domain = state.user.email.split("@")[1];

        let portals = NAV.getPortals(state.client.key);

        if (to.params.module) {
          document.title =
            `${state.client.key.toUpperCase()}: ${to.params.module.toUpperCase()}` || "Touchpoint";
        } else {
          document.title = `${state.client.key.toUpperCase()}` || "Touchpoint";
        }

        let portal = false;

        _each(portals, (p) => {
          if (p.domain && p.domain.includes(user_domain)) {
            portal = p;
          }
        });

        if (portal !== false) {
          state.user.is_supplier = true;
          let module = portal.name.toLowerCase().replace(" ", "-");

          if (to.href.includes("mobile")) return next();
          if (to.params.module == module && to.params.slug == portal.slug) return next();

          if (to.name == 'profile' && to.params.auth0_id == 'account') return next();

          return next({
            name: "Page",
            params: { client: state.client.key, module: module, slug: portal.slug },
          });
        } else {
          if (to.params.module) {
            if (state.user.isTpAdmin === true) return next();

            if (state.user.isAdmin === true) return next();

            let nav_module = null;
            _each(NAV.data, (i) => {
              if (_has(i, "slug")) {
                if (i.slug == to.params.slug) {
                  nav_module = i;
                  return;
                }
              }
              if (_has(i, "children")) {
                _each(i.children, (c) => {
                  if (c.slug == to.params.slug) {
                    nav_module = c;
                    return;
                  }
                });
              }
            });

            state.user.is_supplier = false;

            return next();
          }

          return next();
        }

      }


    }

    let client_id = _get(state.client, "client_id", false);
    if (client_id == "") return next({ name: "Home" });

    // Otherwise, log in
    loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!loading.value) {
    return verify();
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (loading.value === false) {
      return verify();
    }
  });
};

async function doAuth(options, callbackRedirect) {
  client = await createAuth0Client({
    ...options,
  });

  try {
    // If the user is returning to the app after authentication
    if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback();

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      callbackRedirect(appState);
    }
  } catch (e) {
    state.error = e;
  } finally {
    // Initialize our internal authentication state
    state.isAuthenticated = await client.isAuthenticated();
    state.user = await client.getUser();

    if (state.isAuthenticated) {
      const accessToken = await client.getTokenSilently();

      let token = await client.getIdTokenClaims();

      if (token) state.token = token.__raw;
      if (accessToken) state.accessToken = accessToken;
    }
    state.loading = false;
  }
}

export const setupAuth = async () => {
  return {
    install: (app) => {
      app.config.globalProperties.$auth = authPlugin;
    },
  };
};
